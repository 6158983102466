( function() {

    document.addEventListener("DOMContentLoaded", () => {
        const sliderController = document.getElementById('industry-slider-controller'),
              sliderItemsEl = document.getElementById('industry-slider-items'),
              sliderSpeed = 1000;
              

            
        let setInitialSlide = 2,
        isDesktop;

        var industryControlSlider = new Swiper(sliderController, {
            slidesPerView: 6,
            initialSlide: setInitialSlide
        });

        var industryItemsSlider = new Swiper(sliderItemsEl, {
            slidesPerView: 1,
            speed:sliderSpeed,
            initialSlide: setInitialSlide,
            spaceBetween: 120,
            allowTouchMove: false,
            breakpoints: {
                1920: {
                    spaceBetween: 180,
                }
            }
        });

        var sliderItems = document.querySelectorAll('#industry-slider-items .swiper-slide');

        const resetControlItems = () => {
            controlItems.forEach(function (item, index) {
                item.classList.remove('active');
                item.classList.remove('swiper-slide-active');
            });
        }
        var controlItems = document.querySelectorAll('#industry-slider-controller .swiper-slide');
        
        function initControlClickEvents() {
            
            controlItems.forEach(function (item, index) {
                item.addEventListener('click', function (event) {
                    event.preventDefault();
                    if (isDesktop == true) {
                        resetControlItems();
                        industryItemsSlider.slideTo(index, sliderSpeed);
                        item.classList.add('active');
                    } else {
                        window.location.href = item.getAttribute('href');
                    }
                });
            });
        }

        // slide change handlers
        industryControlSlider.on('slideChange', function () {
            industryItemsSlider.slideTo(this.activeIndex, sliderSpeed);
        });

        industryItemsSlider.on('slideChangeTransitionEnd', function () {
            // update tabindex
            sliderItems.forEach(function (item) {
                if ( item.classList.contains('swiper-slide-active') ) {                    
                    item.querySelector('.button').setAttribute('tabindex', '0');;
                } else {
                    item.querySelector('.button').setAttribute('tabindex', '-1');
                }
            });
        });

        /**
         * Handles function calls in major breakpoints.
         */
        enquire.register("screen and (max-width:768px)", {
            match : function() {
                resetControlItems();
                // not ideal to repeat code but had problems with destroy and init on resizes
                var industryControlSlider = new Swiper(sliderController, {
                    slidesPerView: 6,
                    initialSlide: setInitialSlide
                });
                isDesktop = false;
                initControlClickEvents();
            },
            unmatch : function() {
                setInitialSlide = 2;
                controlItems[2].classList.add('active');
                isDesktop = true;
                initControlClickEvents();
            }
        });

        enquire.register("screen and (min-width:768px)", {
            match : function() {
                setInitialSlide = 2;
                isDesktop = true;
                initControlClickEvents();
            },
            unmatch : function() {
                industryItemsSlider.slideTo(2, sliderSpeed);
                industryControlSlider.slideTo(2, sliderSpeed);
                setInitialSlide = 2;
                isDesktop = false;
                initControlClickEvents();
            }
        });

    });
}() );